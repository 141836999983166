import { useState } from 'react';
import * as React from 'react';
import { AppConfig } from '../../App.config';
import { Auth } from '../../Shared/Clients/Auth';
import { Http } from '../../Shared/Clients/Http';
import { Button } from '../../Shared/Components/Button';
import { Icon } from '../../Shared/Components/Icon';
import {
    PageTitle,
    PageTitleDescription,
    SectionTitle,
    Row,
    Col,
} from '../../Shared/Components/Layout';
import { useTrainee } from '../../Shared/Hooks/useTrainee';
import * as Styled from './UserDetailScreen.styled';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from '../../Shared/Components/Spinner';
import { Section } from '../../Shared/Components/Section';
import { useTraineeEngagements } from '../../Shared/Hooks/useTraineeEngagements';
import { Table } from '../../Shared/Components/Table';
import { Select } from '../../Shared/Components/Select';

export const getDateText = (date: Date): string => {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export function UserDetailScreen(): React.ReactElement {
    const [isSaving, setSaving] = useState<boolean>(false);
    const { trainingId, email, userId } = useParams<{
        trainingId: string;
        email: string;
        userId: string;
    }>();
    const trainee = useTrainee(trainingId, userId);
    const history = useHistory();
    const engagements = useTraineeEngagements(trainingId, userId);
    const [isMasterAdmin, setMasterAdmin] = React.useState<boolean>(false);
    const [freeTrailDays, setFreeTrialDays] = React.useState<string>('365');

    React.useEffect(() => {
        Auth.isMasterAdmin().then(setMasterAdmin);
    }, []);

    const tableData = React.useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const result: any[] = [];

        (engagements || []).forEach((v) => {
            result.push({
                date: getDateText(new Date(v.dateTime)),
                score: v.engagementScore,
                contents: v.completedContents.length,
                feedbacks: v.completedFeedbacks.length,
            });
        });

        result.sort((a, b) => (a.date < b.date ? 1 : -1));

        return result;
    }, [engagements]);

    const handleBackButtonPressed = (): void => {
        history.push(`/trainings/${trainingId}/find-user`);
    };

    const handleDeleteUserRequested = async (): Promise<void> => {
        setSaving(true);

        try {
            const userId = await Http.request({
                url: `${AppConfig.endpoints.identity.url}/admin/account/${email}`,
                method: 'delete',
                responseType: 'text',
            });

            try {
                await Http.request({
                    url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${userId}`,
                    method: 'delete',
                });
            } catch {}

            try {
                await Http.request({
                    url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${userId}/subscription`,
                    method: 'delete',
                });
            } catch {}
        } catch {
            alert('User not found');
            setSaving(false);

            return;
        }

        setSaving(false);
        alert('Done!');

        history.goBack();
    };

    const addAssessment = async (): Promise<void> => {
        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${trainee?.userId}`,
                method: 'post',
            });

            alert('Success!');
            history.go(0);
        } catch {
            alert('Failed!');
        }
    };

    const deleteAssessment = async (): Promise<void> => {
        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${trainee?.userId}`,
                method: 'delete',
            });

            alert('Success!');
            history.go(0);
        } catch {
            alert('Failed!');
        }
    };

    const addActiveSubscription = async (): Promise<void> => {
        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${trainee?.userId}/subscription/active/${freeTrailDays}`,
                method: 'post',
            });

            alert('Success!');
            history.go(0);
        } catch {
            alert('Failed!');
        }
    };

    const expireSubscription = async (): Promise<void> => {
        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${trainee?.userId}/subscription/expire`,
                method: 'post',
            });

            alert('Success!');
            history.go(0);
        } catch {
            alert('Failed!');
        }
    };

    const deleteSubscription = async (): Promise<void> => {
        try {
            await Http.request({
                url: `${AppConfig.endpoints.admin.url}/trainings/${trainingId}/trainees/${trainee?.userId}/subscription`,
                method: 'delete',
            });

            alert('Success!');
            history.go(0);
        } catch {
            alert('Failed!');
        }
    };

    return (
        <>
            {isSaving && <Spinner />}

            <PageTitleDescription>App Management</PageTitleDescription>
            <PageTitle>
                Users <Icon name="chevron-right" size={24} /> {trainee?.firstName}{' '}
                {trainee?.lastName} ({trainee?.email})
            </PageTitle>

            <Section
                header={
                    <>
                        {trainee && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button text="Back" onClick={handleBackButtonPressed} secondary />

                                {trainee?.hasActiveSubscription && (
                                    <Button text="Expire" onClick={expireSubscription} danger />
                                )}

                                <Select
                                    name="freeTrialDays"
                                    onChange={(label, newVal) => setFreeTrialDays(newVal)}
                                    options={[
                                        { value: '1 week', key: '7' },
                                        { value: '2 weeks', key: '14' },
                                        { value: '1 month', key: '30' },
                                        { value: '2 month', key: '60' },
                                        { value: '3 month', key: '90' },
                                        { value: '6 month', key: '180' },
                                        { value: '1 year', key: '365' },
                                        { value: '2 years', key: '730' },
                                        { value: '5 years', key: '1825' },
                                        { value: '100 years', key: '36500' },
                                    ]}
                                    value={freeTrailDays}
                                    vertical
                                />

                                <Button text="Add Free Trial" onClick={addActiveSubscription} />

                                {isMasterAdmin && (
                                    <>
                                        {trainee && (
                                            <Button
                                                text="Delete Assessment"
                                                onClick={deleteAssessment}
                                                secondary
                                            />
                                        )}

                                        {!trainee && (
                                            <Button
                                                text="Add Assessment"
                                                onClick={addAssessment}
                                                secondary
                                            />
                                        )}

                                        {trainee.hasSubscription && (
                                            <Button
                                                text="Delete Subscription"
                                                onClick={deleteSubscription}
                                                secondary
                                            />
                                        )}
                                    </>
                                )}
                                <Button
                                    text="Delete User"
                                    onClick={handleDeleteUserRequested}
                                    danger
                                />
                            </div>
                        )}
                    </>
                }
                footer={
                    <>
                        {trainee && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button text="Back" onClick={handleBackButtonPressed} secondary />

                                {trainee?.hasActiveSubscription && (
                                    <Button text="Expire" onClick={expireSubscription} danger />
                                )}

                                <Select
                                    name="freeTrialDays"
                                    onChange={(label, newVal) => setFreeTrialDays(newVal)}
                                    options={[
                                        { value: '1 week', key: '7' },
                                        { value: '2 weeks', key: '14' },
                                        { value: '1 month', key: '30' },
                                        { value: '2 month', key: '60' },
                                        { value: '3 month', key: '90' },
                                        { value: '6 month', key: '180' },
                                        { value: '1 year', key: '365' },
                                        { value: '2 years', key: '730' },
                                        { value: '5 years', key: '1825' },
                                        { value: '100 years', key: '36500' },
                                    ]}
                                    value={freeTrailDays}
                                    vertical
                                />

                                <Button text="Add Free Trial" onClick={addActiveSubscription} />

                                {isMasterAdmin && (
                                    <>
                                        {trainee && (
                                            <Button
                                                text="Delete Assessment"
                                                onClick={deleteAssessment}
                                                secondary
                                            />
                                        )}

                                        {!trainee && (
                                            <Button
                                                text="Add Assessment"
                                                onClick={addAssessment}
                                                secondary
                                            />
                                        )}

                                        {trainee.hasSubscription && (
                                            <Button
                                                text="Delete Subscription"
                                                onClick={deleteSubscription}
                                                secondary
                                            />
                                        )}

                                        <Button
                                            text="Delete User"
                                            onClick={handleDeleteUserRequested}
                                            danger
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </>
                }
            >
                <Row>
                    <Col size={1}>
                        <Section backgroundColor="rgba(0, 0, 0, 0.02)">
                            {trainee && (
                                <>
                                    <SectionTitle>User information</SectionTitle>

                                    <Styled.DataGroup>
                                        <Styled.Label>User Id</Styled.Label>
                                        {trainee.userId}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Name</Styled.Label> {trainee.firstName}{' '}
                                        {trainee.lastName}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Email</Styled.Label> {trainee.email}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Auth provider</Styled.Label>{' '}
                                        {trainee.authProvider || 'Email'}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>User type</Styled.Label>{' '}
                                        {trainee.traineeType || 'N/A'}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Has completed assessment</Styled.Label>{' '}
                                        {trainee.hasCompletedAssessment ? 'Yes' : 'No'}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Subscription status</Styled.Label>{' '}
                                        {trainee.subscriptionStatus?.toUpperCase()}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>Subscription from</Styled.Label>{' '}
                                        {trainee.store?.toUpperCase()}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>In free trial</Styled.Label>{' '}
                                        {trainee.isInFreeTrial ? 'Yes' : 'No'}
                                    </Styled.DataGroup>
                                    <Styled.DataGroup>
                                        <Styled.Label>In subscription trial</Styled.Label>{' '}
                                        {trainee.isInSubscriptionTrial ? 'Yes' : 'No'}
                                    </Styled.DataGroup>
                                    {!!trainee.memberSinceDate && (
                                        <Styled.DataGroup>
                                            <Styled.Label>Member since</Styled.Label>{' '}
                                            {new Date(trainee.memberSinceDate).toLocaleString()}
                                        </Styled.DataGroup>
                                    )}
                                    {!!trainee.subscriptionEndDate && (
                                        <Styled.DataGroup>
                                            <Styled.Label>Subscription end date</Styled.Label>{' '}
                                            {new Date(trainee.subscriptionEndDate).toLocaleString()}
                                        </Styled.DataGroup>
                                    )}
                                    {!!trainee.subscriptionRenewalDate && (
                                        <Styled.DataGroup>
                                            <Styled.Label>Subscription renewal date</Styled.Label>{' '}
                                            {new Date(
                                                trainee.subscriptionRenewalDate
                                            ).toLocaleString()}
                                        </Styled.DataGroup>
                                    )}
                                </>
                            )}
                        </Section>
                    </Col>
                    <Col size={2}>
                        <Section>
                            <SectionTitle>Engagements</SectionTitle>

                            <Table
                                isLoading={engagements === null}
                                columns={[
                                    {
                                        key: 'date',
                                        name: 'Date',
                                        style: { textAlign: 'left', flexGrow: 1 },
                                    },
                                    {
                                        key: 'score',
                                        name: 'Score',
                                        style: { textAlign: 'left', maxWidth: 150 },
                                    },
                                    {
                                        key: 'contents',
                                        name: '# of Contents',
                                        style: { textAlign: 'left', maxWidth: 150 },
                                    },
                                    {
                                        key: 'feedbacks',
                                        name: '# of Feedbacks',
                                        style: { textAlign: 'left', maxWidth: 150 },
                                    },
                                ]}
                                data={tableData}
                            />
                        </Section>
                    </Col>
                </Row>
            </Section>
        </>
    );
}
