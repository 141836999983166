export const AppConfig = {
    appName: process.env.REACT_APP_NAME,
    environment: process.env.REACT_APP_ENVIRONMENT,
    aiKey: process.env.REACT_APP_AI_KEY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    defaultTrainingId: process.env.REACT_APP_DEFAULT_TRAINING_ID,
    endpoints: {
        identity: {
            url: process.env.REACT_APP_API_BASE_URL,
            resource: 'Apeak.Identity',
        },
        admin: {
            url: `${process.env.REACT_APP_API_BASE_URL}/v3/admin`,
            // url: 'https://localhost:5041',
            resource: 'Apeak.Admin',
        },
        cdn: {
            url: process.env.REACT_APP_CDN_URL,
        },
    },
};
